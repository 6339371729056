<template>
    <div class="dashboard-container">
        <div v-if="!dialog.refresh">
            <el-radio-group v-model="radioId" @change="typeChange">
                <el-radio-button
                    label="1"
                    v-if="
                        $hasBtn('sys:permissions:assessment:daily:evaluation')
                    "
                    >每日测评</el-radio-button
                >
                <el-radio-button
                    label="2"
                    v-if="
                        $hasBtn('sys:permissions:assessment:monthly:evaluation')
                    "
                    >月度测评</el-radio-button
                >
            </el-radio-group>
        </div>
        <el-card v-if="!dialog.refresh">
            <el-row type="flex">
                <el-col :span="10">
                    <span class="text-primary text-pad-right">日期：</span>
                    <el-date-picker
                        v-model="condition.date"
                        type="daterange"
                        class="input searchInput"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']"
                        size="mini"
                        @change="changeTime"
                    />
                </el-col>
                <div v-if="companyType" class="pl-1 pr-1">
                    <span class="text-primary text-pad-right">公司名称</span>
                    <el-select
                        v-model="companyId"
                        filterable
                        size="mini"
                        class="input searchInput"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(item, index) in company"
                            :key="index"
                            :value="item.id"
                            :label="item.companyName"
                        />
                    </el-select>
                </div>
            </el-row>
        </el-card>
        <el-card v-if="!dialog.refresh" style="margin-top: 20px">
            <el-row>
                <vxe-toolbar ref="xToolbar" custom>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col></el-col>
                            <el-col
                                :span="4"
                                style="text-align: right; margin-top: 12px"
                            >
                                <el-button
                                    size="mini"
                                    type="primary"
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:report:menta:assessment:import'
                                        )
                                    "
                                    @click="defenseExport"
                                    >导出excel</el-button
                                >
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="typeChange"
                                    >刷新</el-button
                                >
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    @custom="handleCustom"
                    id="user_id"
                    :custom-config="{ storage: true }"
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="580"
                    row-id="ID"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                >
                    <vxe-table-column title="企业名称" field="companyName">
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.companyName }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="mentalTypeName" title="量表名称">
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.mentalTypeName }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="queryTime" title="查询日期">
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.queryTime }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="totalNumber" title="测评总人数">
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.totalNumber }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        field="completedNumber"
                        title="完成测评人数"
                    >
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.completedNumber }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        field="uncompletedNumber"
                        title="未完成测评人数"
                    >
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.uncompletedNumber }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="completionRate" title="完成率%">
                        <template #default="{ row }">
                            <template>
                                <div
                                    style="cursor: pointer"
                                    @click="openDialog(row)"
                                >
                                    {{ row.completionRate }}
                                </div>
                            </template>
                        </template>
                    </vxe-table-column>

                    <!-- <vxe-table-column
                        field="phoneUrl"
                        title="学员照片"
                    ></vxe-table-column> -->
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
        <el-card v-if="dialog.refresh" style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="1">
                                <vxe-button @click="dialog.refresh = false"
                                    >返回</vxe-button
                                >
                            </el-col>
                            <el-col></el-col>
                            <el-col :span="6">
                                <vxe-button @click="defenseExport2"
                                    >批量导出试卷</vxe-button
                                >
                                <vxe-button @click="typeChange1"
                                    >刷新</vxe-button
                                >
                                <vxe-button @click="defenseExport1"
                                    >导出Excel</vxe-button
                                >
                            </el-col>
                            <el-col :span="1"></el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <div
                            style="
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                border: 1px solid #cccccc;
                                font-size: 18px;
                                font-weight: bold;
                            "
                            class="text-center"
                        >
                            {{ table1.companyName }}
                            <span v-if="radioId == 1">每日</span
                            ><span v-if="radioId == 2">每月</span>状态测评统计表
                        </div>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                        font-size: 16px;
                                        font-weight: bold;
                                    "
                                    class="text-center"
                                >
                                    查询日期
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                    "
                                    class="text-center"
                                >
                                    {{ table1.queryTime }}
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                        font-size: 16px;
                                        font-weight: bold;
                                    "
                                    class="text-center"
                                >
                                    公司名称
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                    "
                                    class="text-center"
                                >
                                    {{ table1.companyName }}
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                        font-size: 16px;
                                        font-weight: bold;
                                    "
                                    class="text-center"
                                >
                                    测评总人数
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                    "
                                    class="text-center"
                                >
                                    {{ table1.totalNumber }}
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                        font-size: 16px;
                                        font-weight: bold;
                                    "
                                    class="text-center"
                                >
                                    完成率%
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div
                                    style="
                                        line-height: 50px;
                                        border: 1px solid #cccccc;
                                    "
                                    class="text-center"
                                >
                                    {{ table1.completionRate }}%
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <el-table
                    :data="table1.list"
                    stripe
                    height="550"
                    border
                    style="width: 100%"
                >
                    <el-table-column
                        label="序号"
                        type="index"
                        width="50"
                        align="center"
                    >
                        <template scope="scope">
                            <span>{{ scope.$index + 1 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        label="用户名称"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号" align="center">
                    </el-table-column>
                    <el-table-column label="测评状态" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.assessmentStatus == 0"
                                >已测评</span
                            >
                            <span v-if="scope.row.assessmentStatus == 1"
                                >未测评</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="radioId == 2"
                        prop="submitTime"
                        label="测评时间"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="detectionScore"
                        label="测评得分"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        v-if="radioId == 2"
                        prop="authenticity"
                        label="真实性"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="feedbackResults"
                        label="测评结果"
                        align="center"
                        :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="feedbackScheme"
                        label="结果详情"
                        align="center"
                        :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column label="查看试卷" align="center">
                        <template v-slot="{ row }">
                            <el-button
                                type="text"
                                @click="openEvaluation(row.id)"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table1.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table1.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table1.total"
                    @size-change="handleSizeChange1"
                    @current-change="handleCurrentChange1"
                />
            </el-row>
        </el-card>
        <div v-if="evaluation" class="lookDetails" @click="closeAllBox($event)">
            <div class="lookDetailsBox">
                <el-row :gutter="20">
                    <el-col :span="1">
                        <div style="width: 1px; height: 1px"></div>
                    </el-col>
                    <el-col :span="22">
                        <div
                            class="text-center"
                            style="font-size: 18px; font-weight: bold"
                        >
                            测评情况
                        </div>
                    </el-col>
                    <el-col :span="1">
                        <i
                            @click="closeLearning"
                            class="el-icon-error"
                            style="font-size: 22px; cursor: pointer"
                        ></i>
                    </el-col>
                </el-row>
                <div class="lookDetailsCont">
                    <el-row
                        :gutter="22"
                        style="padding-top: 10px; font-weight: bold"
                    >
                        <el-col :span="21">
                            <div
                                style="
                                    border-left: 3px solid #55aa00;
                                    height: 16px;
                                    line-height: 16px;
                                    padding-left: 5px;
                                    font-size: 18px;
                                "
                            >
                                测评结果
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <vxe-button @click="defenseExport3"
                                >导出</vxe-button
                            >
                        </el-col>
                    </el-row>
                    <el-row :gutter="22" style="padding-top: 15px">
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                测评量表：
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.mentalTypeName
                                        ? testPaper.mentalTypeName
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3" v-if="radioId == 2">
                            <div style="height: 30px; line-height: 30px">
                                真实性：
                            </div>
                        </el-col>
                        <el-col :span="9" v-if="radioId == 2">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.lieFeedbackResults
                                        ? testPaper.lieFeedbackResults
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="22">
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                作答用户：
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.realName
                                        ? testPaper.realName
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                测评得分：
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.detectionScore
                                        ? testPaper.detectionScore + "分"
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="22">
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                作答时长：
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.answerTime
                                        ? testPaper.answerTime + "/分钟"
                                        : "--"
                                }}
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                提交时间：
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    color: #666666;
                                "
                            >
                                {{
                                    testPaper.submitTime
                                        ? testPaper.submitTime
                                        : "--"
                                }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="22" style="padding-bottom: 15px">
                        <el-col :span="3">
                            <div style="height: 30px; line-height: 30px">
                                测评结果：
                            </div>
                        </el-col>
                        <el-col :span="21">
                            <span
                                v-for="(
                                    item, index
                                ) in testPaper.evaluationResultsVos"
                                :key="index"
                                style="line-height: 30px"
                            >
                                <span style="color: #007aff"
                                    >【{{ item.feedbackResults }}】</span
                                >
                                <span>{{ item.feedbackScheme }}</span>
                            </span>
                        </el-col>
                    </el-row>
                    <div
                        style="
                            border-left: 3px solid #55aa00;
                            height: 16px;
                            line-height: 16px;
                            padding-left: 5px;
                            font-size: 18px;
                        "
                    >
                        作答情况
                    </div>
                    <div style="padding-top: 10px" v-if="subjects.length < 1">
                        未参加测评
                    </div>
                    <div
                        style="
                            padding-top: 10px;
                            line-height: 30px;
                            text-indent: 30px;
                        "
                        v-if="subjects.length > 0"
                    >
                        {{ testPaper.description }}
                    </div>
                    <div v-for="(item, index) in subjects" :key="index">
                        <div
                            style="
                                line-height: 22px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            "
                        >
                            {{ index + 1 }}, {{ item.topicTitle }}
                        </div>
                        <div class="optionClass">
                            <div
                                style="padding: 0 10px"
                                v-for="(items, indexs) in item.sos"
                                :key="indexs"
                            >
                                <div
                                    :class="{ colActive: items.isChoose === 0 }"
                                >
                                    <span
                                        class="xuanxiang"
                                        :class="{
                                            borderActive: items.isChoose === 0,
                                        }"
                                        >{{ items.topicOptions }}</span
                                    >
                                    {{ items.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import { journalism, journalismRelease, company } from "@/api";
export default {
    name: "Index",
    data() {
        return {
            columns: [
                "companyName",
                "mentalTypeName",
                "queryTime",
                "totalNumber",
                "completedNumber",
                "uncompletedNumber",
                "completionRate",
            ],

            loading: false,
            condition: {
                releaseStatus: "",
                search: "",
                date: [],
                start: "",
                end: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            table1: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
                queryTime: "",
                completionRate: "",
                companyName: "",
                totalNumber: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                show: false,
            },
            company: [],
            loginInfo: {},
            radioId: "1",
            companyId: "",
            companyId1: "",
            queryTime: "",
            companyType: false,
            evaluation: false,
            testPaper: {},
            subjects: [],
            evaluationId: "",
        };
    },
    created() {
        this.getCompany();
        if (this.$companyType == 0) {
            this.companyType = true;
        } else {
            this.companyType = false;
        }
    },
    methods: {
        rowStyle,
        headerStyle,
        handleCustom(e) {
            const columns = JSON.parse(
                JSON.stringify(e.$table.$toolbar.columns)
            );

            this.columns = columns
                .filter((item) => item.visible)
                .map((item) => item.property)
                .filter((item) => item);
        },
        changeCondition() {
            this.table.currentPage = 1;
            if (this.radioId == 1) {
                this.getList(
                    "/course/server/escort/web/mentalUser/getDailyAssessment"
                );
            } else {
                this.getList(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessment"
                );
            }
            this.$refs.table.clearCheckboxRow();
        },
        openEvaluation(id) {
            this.evaluation = true;
            this.evaluationId = id;
            this.getSubjects(id);
            this.getTestPaper(id);
        },
        closeLearning() {
            this.evaluation = false;
            this.evaluationId = "";
        },
        closeAllBox(o) {
            if (o.target.className == "lookDetails") {
                this.evaluation = false;
                this.evaluationId = "";
            }
        },
        getTestPaper(id) {
            let that_ = this;
            that_.testPaper = {};
            this.$axiosReq(
                "/course/server/escort/web/mentalUser/testPaper/" + id,
                null,
                null,
                "get"
            ).then((res) => {
                if (res.data) {
                    that_.testPaper = res.data;
                }
            });
        },
        getSubjects(id) {
            let that_ = this;
            that_.subjects = [];
            this.$axiosReq(
                "/course/server/escort/web/mentalUser/subjects/" + id,
                null,
                null,
                "get"
            ).then((res) => {
                if (res.data && res.data.length > 0) {
                    that_.subjects = res.data;
                }
            });
        },
        changeTime(v) {
            this.table.currentPage = 1;
            if (v === null) {
                this.condition.start = "";
                this.condition.end = "";
            } else {
                this.condition.start = v[0];
                this.condition.end = v[1];
            }
            if (this.radioId == 1) {
                this.getList(
                    "/course/server/escort/web/mentalUser/getDailyAssessment"
                );
            } else {
                this.getList(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessment"
                );
            }
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    currentPage: 1,
                    pageSize: 1000,
                },
                "get"
            ).then((res) => {
                if (res.data.content && res.data.content.length > 0) {
                    this.company = res.data.content;
                    this.companyId = this.company[0].id;
                    this.getList(
                        "/course/server/escort/web/mentalUser/getDailyAssessment"
                    );
                }
            });
        },
        getList(url) {
            this.loading = true;
            this.$axiosReq(
                url,
                null,
                {
                    startTime: this.condition.start,
                    endTime: this.condition.end,
                    companyId: this.companyId,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(Number(res.data.totalElements));
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getDetails(url) {
            this.loading = true;
            this.$axiosReq(
                url,
                null,
                {
                    queryTime: this.queryTime,
                    companyId: this.companyId1,
                    currentPage: this.table1.currentPage,
                    pageSize: this.table1.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table1.queryTime = res.data.queryTime;
                    this.table1.completionRate = res.data.completionRate;
                    this.table1.companyName = res.data.companyName;
                    this.table1.totalNumber = res.data.totalNumber;
                    this.table1.list = res.data.statisticsEachVos.content;
                    this.table1.total = Number(
                        Number(res.data.statisticsEachVos.totalElements)
                    );
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        changeStatus(v, row) {
            this.$axiosReq(journalismRelease + row.id, null, null, "put").then(
                (res) => {
                    this.$message.success(res.msg);
                    this.getList(this.condition.company);
                }
            );
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            if (this.radioId == 1) {
                this.getList(
                    "/course/server/escort/web/mentalUser/getDailyAssessment"
                );
            } else {
                this.getList(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessment"
                );
            }
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            if (this.radioId == 1) {
                this.getList(
                    "/course/server/escort/web/mentalUser/getDailyAssessment"
                );
            } else {
                this.getList(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessment"
                );
            }
        },
        handleSizeChange1(pageSize) {
            this.table1.pageSize = pageSize;
            this.table1.currentPage = 1;
            if (this.radioId == 1) {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getDailyAssessmentDetails"
                );
            } else {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessmentDetails"
                );
            }
        },
        handleCurrentChange1(currentPage) {
            this.table1.currentPage = currentPage;
            if (this.radioId == 1) {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getDailyAssessmentDetails"
                );
            } else {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessmentDetails"
                );
            }
        },
        refreshDialog() {
            this.dialog.refresh = false;
            if (this.radioId == 1) {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getDailyAssessmentDetails"
                );
            } else {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessmentDetails"
                );
            }
        },
        openDialog(row) {
            this.table1.list = [];
            this.table1.currentPage = 1;
            this.table1.pageSize = 10;
            this.dialog.refresh = true;
            this.queryTime = row.queryTime;
            this.companyId1 = row.companyId;
            if (this.radioId == 1) {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getDailyAssessmentDetails"
                );
            } else {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessmentDetails"
                );
            }
        },
        typeChange() {
            this.table.list = [];
            this.table.total = 0;
            if (this.radioId == 1) {
                this.getList(
                    "/course/server/escort/web/mentalUser/getDailyAssessment"
                );
            } else {
                this.getList(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessment"
                );
            }
        },
        typeChange1() {
            this.table1.list = [];
            this.table1.total = 0;
            if (this.radioId == 1) {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getDailyAssessmentDetails"
                );
            } else {
                this.getDetails(
                    "/course/server/escort/web/mentalUser/getMonthlyAssessmentDetails"
                );
            }
        },
        defenseExport() {
            let urlNow = "";
            if (this.radioId == 1) {
                urlNow =
                    "/course/server/escort/web/mentalUser/exportDailyAssessment";
            } else {
                urlNow =
                    "/course/server/escort/web/mentalUser/exportMonthlyAssessment";
            }
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            console.log(this.columns);

            const fields = this.columns.length > 0 ? this.columns + "" : null;
            this.$axiosReq(
                urlNow,
                null,
                {
                    companyId: this.companyId,
                    startTime: this.condition.start,
                    endTime: this.condition.end,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                    fields: fields,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        defenseExport1() {
            let urlNow = "";
            if (this.radioId == 1) {
                urlNow =
                    "/course/server/escort/web/mentalUser/exportDailyAssessmentDetails";
            } else {
                urlNow =
                    "/course/server/escort/web/mentalUser/exportMonthlyAssessmentDetails";
            }
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                urlNow,
                null,
                {
                    companyId: this.companyId1,
                    queryTime: this.queryTime,
                    currentPage: this.table1.currentPage,
                    pageSize: this.table1.pageSize,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        defenseExport2() {
            let urlNow = "";
            if (this.radioId == 1) {
                urlNow =
                    "/course/server/escort/web/mentalUser/export/dailyTest";
            } else {
                urlNow =
                    "/course/server/escort/web/mentalUser/export/monthlyTest";
            }
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                urlNow,
                null,
                {
                    companyId: this.companyId1,
                    queryTime: this.queryTime,
                },
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        defenseExport3() {
            const loading = this.$loading({
                lock: true,
                text: "正在导出！",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$axiosReq(
                "/course/server/escort/web/mentalUser/export/" +
                    this.evaluationId,
                null,
                null,
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally(() => {
                    loading.close();
                });
        },
        order(row) {
            return row.rowIndex;
        },
    },
};
</script>

<style scoped>
.lookDetails {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}

.lookDetailsBox {
    width: 60%;
    height: calc(100vh - 130px);
    padding: 20px;
    background-color: #ffffff;
    margin: 100px auto 0;
    border-radius: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}

.lookDetailsBox::-webkit-scrollbar {
    display: none;
}

.lookDetailsCont {
    width: 80%;
    border-top: 1px solid #cccccc;
    margin: 10px auto 0;
    padding-top: 10px;
    font-size: 16px;
}

.optionClass {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
}

.xuanxiang {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.colActive {
    color: #1ace1a;
}

.borderActive {
    border: 1px solid #1ace1a !important;
}
</style>
